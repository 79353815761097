// 资讯详情
// sle
<template>
  <div class="body flex-row justify-center">
    <div class="main flex justify-between">
      <div
        class="left flex-column align-center justify-center"
        :style="{ minHeight: minHeight + 'px' }"
      >
        <div class="title">{{ obj.title }}</div>
        <div class="date">日期：{{ obj.creationTimeS }}</div>
        <div class="img">
          <img :src="obj.titlePicUrl" alt="标题图片" />
        </div>
        <div  class="detail " v-html="obj.titleDetails"></div>
      </div>
      <div class="right">
        <div class="head">
          <span @click="moreClick" class="headTitle">更多资讯</span>
        </div>
        <div class="line_h"></div>
        <div class="list flex-column">
          <div
            class="item"
            v-for="item in list"
            :key="item.id"
            @click="itemClick(item)"
          >
            <div class="ellipsis" :title="item.title">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../components/sameVariable'

export default defineComponent({
  components: {
  },
  data () {
    return {
      top: 10,
      obj: {
        spinning: false,
      },
      detailSpinning: false,
      listSpinning: false,
      list: [], // 更多资讯列表
      titleDetails: '',
    }
  },
  // watch: {
  //   '$route' (to, from) { // 监听URL地址栏参数变化
  //     const queryHobby = this.$route.query.hobby
  //     if (queryHobby) {
  //       this.getHobby(queryHobby)
  //     }
  //   },
  // },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    const id = this.$route.query.id
    this.getInformationAnnList()
    this.getInformationAnnData(id)
  },
  // 方法
  methods: {
    // 获取更多资讯列表
    getInformationAnnList () {
      this.listSpinning = true
      api
        .get('/api/app/sys-information-ann/paging-sys-information-ann-list', {
          params: {
            PageSize: 5,
            Pagination: 1,
          },
        })
        .then(({ data }) => {
          this.listSpinning = false
          this.list = data.list
        })
        .catch(err => {
          this.listSpinning = false
          // this.$message.error('获取资讯列表失败')
          console.log(err)
        })
    },
    // 获取当前资讯详情
    getInformationAnnData (id) {
      this.detailSpinning = true
      api
        .get('/api/app/sys-information-ann/sys-information-ann', {
          params: {
            Id: id,
          },
        })
        .then(({ data }) => {
          this.detailSpinning = false
          data.titleDetails = data.titleDetails.replace(/<oembed url/ig, "<video controls='controls' src").replace(/oembed>/ig, 'video')
          // console.log(data.titleDetails).replace(/<oembed url/ig, "<video controls='controls' src").replace(/oembed>/ig, 'video')
          this.obj = data
          // this.titleDetails = this.obj.titleDetails.replace(/<img.*?(?:>|\/>)/gi, '').replace(/<figure.*?(?:>|\/>)/g, '')
        })
        .catch(err => {
          this.detailSpinning = false
          // this.$message.error('获取资讯详情失败')
          console.log(err)
        })
    },
    // 更多资讯按钮
    moreClick () {
      this.$router.push({ path: '/WorkManagement/InformationAnnList' })
    },
    // 点击右侧子项
    itemClick (e) {
      this.getInformationAnnData(e.id)
      this.$route.query.id = e.id
      window.history.replaceState(null, null, '?id=' + e.id)
      // this.$router.push({
      //   path: '/WorkManagement/InformationAnnDetail',
      //   query: {
      //     id: e.id,
      //   },
      // }).finally(() => {
      //   var url = window.location.href
      //   window.location.href = url
      //   // location.reload()
      // })
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.body {
  width: 100%;
  .main {
    width: 82%;
    position: relative;
    .left {
      width: 74%;
      background: #ffffff;
      border-radius: 4px;
      padding: 30px;
      margin-right: 20px;
      .img {
        width: 100%;
        max-height: 300px;
        min-height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .title {
        font-size: 22px;
        font-weight: 400;
      }
      .date {
        margin: 10px 0 20px 0;
        font-size: 14px;
        color: #999999;
        font-weight: 400;
      }
      .detail {
        margin-top: 30px;
      }
    }
    .right {
      width: 24%;
      // max-height: 500px;
      background: #ffffff;
      border-radius: 4px;
      // right: 0;
      // position: absolute;
      right: 4%;
      position: fixed;
      .head {
        padding: 21px;
        .headTitle {
          font-size: 18px;
          cursor: pointer;
        }
      }
      .list {
        margin: 0px;
        list-style-type: none;

        .item:before {
          content: "\2022";
          color: #134ccf;
          font-size: 30px;
          height: 30px;
          margin-right: 5px;
        }

        .item {
          font-size: 14px;
          line-height: 25px;
          margin: 5px 15px;
          font-weight: 400;
          cursor: pointer;
          -webkit-line-clamp: 3;
          display: flex;
        }
      }
    }
  }
}
.detail {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
::v-deep(img) {
  width: auto;
  height: auto;
  max-width: 50%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
}
::v-deep(p){
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
// .detail {
//   img {
//     width: 100%;
//   }
// }
</style>
